.Toastify__toast-container {
  width: 360px;
}

.toasterv2 {
  border-radius: 4px !important;
  padding: 12px 20px !important;
  line-height: 1.6em !important;
  z-index: 99999 !important;
}

.toasterv2-success {
  color: #999 !important;
  background-color: #FFF !important;
}

.toasterv2-error, .Toastify__toast--info.toasterv2-error {
  color: #FFF !important;
  background-color: red !important;
}

.toasterv2-warn {
  color: #FFF !important;
  background-color: darkorange !important;
}

.toasterv2 a {
  text-decoration: none;
  border-bottom: 1px dashed #CCC;
}

.toasterv2 button.Toastify__close-button {
  color: rgba(0, 0, 0, 0.4) !important;
  padding-left: 21px !important;
  margin-right: -11px !important;
  margin-top: -7px !important;
}

 .toasterv2-error button.Toastify__close-button {
  color: rgba(255, 255, 255, 0.8) !important;
 }

 .toasterv2-warn button.Toastify__close-button {
  color: rgba(255, 255, 255, 1) !important;
 }

 .toast-promo {
  background-color: #00c300;
  text-align: center;
  font-weight: bold;
  line-height: 2em;
  border-radius: 4px !important;
}